define("discourse/plugins/discourse-multilingual/discourse/initializers/multilingual-initializer", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-tag", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual-route", "discourse/plugins/discourse-multilingual/discourse/lib/multilingual", "discourse/models/composer", "discourse-common/lib/icon-library", "discourse/lib/render-tag", "@ember/object", "@ember/runloop", "jquery", "I18n", "discourse-common/lib/helpers", "@glimmer/tracking", "discourse/lib/sidebar/user/categories-section/category-section-link", "discourse/plugins/discourse-multilingual/discourse/lib/sidebar/user/tags-section/custom-pm-tag-section-link", "discourse/plugins/discourse-multilingual/discourse/lib/sidebar/user/tags-section/custom-tag-section-link", "discourse/plugins/discourse-multilingual/discourse/components/sidebar/edit-navigation-menu/custom-tags-modal", "discourse/lib/utilities", "discourse-common/lib/get-url", "discourse/models/category", "discourse/helpers/category-variables", "@ember/template-factory"], function (_exports, _pluginApi, _decorators, _multilingualTag, _multilingualRoute, _multilingual, _composer, _iconLibrary, _renderTag, _object, _runloop, _jquery, _I18n, _helpers, _tracking, _categorySectionLink, _customPmTagSectionLink, _customTagSectionLink, _customTagsModal, _utilities, _getUrl, _category, _categoryVariables, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function searchTranslatedTags(query, locale) {
    const translations = _I18n.default.tag_translations[locale] || {};
    const lowerQuery = query !== null ? query.toLowerCase() : "";
    const startsWithResults = [];
    const containsResults = [];
    Object.entries(translations).forEach(_ref => {
      let [tag, translation] = _ref;
      if (translation.toLowerCase().startsWith(lowerQuery)) {
        startsWithResults.push({
          id: tag,
          name: translation,
          targetTagId: tag
        });
      } else if (translation.toLowerCase().includes(lowerQuery)) {
        containsResults.push({
          id: tag,
          name: translation,
          targetTagId: tag
        });
      }
    });
    return startsWithResults.concat(containsResults);
  }
  let _extraIconRenderers = [];
  function buildTopicCount(count) {
    return `<span class="topic-count" aria-label="${_I18n.default.t("category_row.topic_count", {
      count
    })}">&times; ${count}</span>`;
  }
  function customCategoryLinkRenderer(category, opts) {
    const currentLocale = _I18n.default.currentLocale();
    const customText = (0, _utilities.escapeExpression)(category.custom_fields.translated_text[currentLocale] || category.original_name);
    let descriptionText = (0, _utilities.escapeExpression)(category[`description_text_${currentLocale}`] || category.original_description_text);
    let restricted = (0, _object.get)(category, "read_restricted");
    let url = opts.url ? opts.url : (0, _getUrl.default)(`/c/${_category.default.slugFor(category)}/${(0, _object.get)(category, "id")}`);
    let href = opts.link === false ? "" : url;
    let tagName = opts.link === false || opts.link === "false" ? "span" : "a";
    let extraClasses = opts.extraClasses ? " " + opts.extraClasses : "";
    let style = `${(0, _categoryVariables.default)(category)}`;
    let html = "";
    let parentCat = null;
    let categoryDir = "";
    let dataAttributes = category ? `data-category-id="${(0, _object.get)(category, "id")}"` : "";
    if (!opts.hideParent) {
      parentCat = _category.default.findById((0, _object.get)(category, "parent_category_id"));
    }
    let siteSettings = (0, _helpers.helperContext)().siteSettings;
    let classNames = `badge-category`;
    if (restricted) {
      classNames += " restricted";
    }
    if (parentCat) {
      classNames += ` --has-parent`;
      dataAttributes += ` data-parent-category-id="${parentCat.id}"`;
    }
    html += `<span 
    ${dataAttributes} 
    data-drop-close="true" 
    class="${classNames}" 
    ${opts.previewColor ? `style="--category-badge-color: #${category.color}"` : ""}
    ${descriptionText ? 'title="' + descriptionText + '" ' : ""}
  >`;
    if (siteSettings.support_mixed_text_direction) {
      categoryDir = isRTL(customText) ? 'dir="rtl"' : 'dir="ltr"';
    }
    if (restricted) {
      html += (0, _iconLibrary.iconHTML)("lock");
    }
    _extraIconRenderers.forEach(renderer => {
      const iconName = renderer(category);
      if (iconName) {
        html += (0, _iconLibrary.iconHTML)(iconName);
      }
    });
    html += `<span class="badge-category__name" ${categoryDir}>${customText}</span>`;
    html += "</span>";
    if (opts.topicCount) {
      html += buildTopicCount(opts.topicCount);
    }
    if (href) {
      href = ` href="${href}" `;
    }
    return `<${tagName} class="badge-category__wrapper ${extraClasses}" ${style.length > 0 ? `style="${style}"` : ""} ${href}>${html}</${tagName}>`;
  }
  var _default = _exports.default = {
    name: "multilingual",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      const currentUser = container.lookup("current-user:main");
      const tagTranslationsService = container.lookup('service:tag-translations');
      if (!siteSettings.multilingual_enabled) {
        return;
      }
      tagTranslationsService.loadTranslations();
      const localeMapping = {
        en: {
          name: "English",
          icon: "flag-us"
        },
        fr: {
          name: "Français",
          icon: "flag-fr"
        },
        de: {
          name: "Deutsch",
          icon: "flag-de"
        },
        nl: {
          name: "Nederlands",
          icon: "flag-nl"
        },
        pl_PL: {
          name: "Polski",
          icon: "flag-pl"
        }
      };
      if (siteSettings.multilingual_content_languages_enabled) {
        _composer.default.serializeOnCreate("content_language_tags", "content_language_tags");
        _composer.default.serializeToTopic("content_language_tags", "topic.content_language_tags");
      }
      (0, _pluginApi.withPluginApi)("1.20.0", api => {
        var _dec, _dec2, _obj, _obj2, _obj3, _obj4;
        api.replaceTagRenderer(_multilingualTag.multilingualTagRenderer);
        _multilingualRoute.discoveryParams.forEach(param => {
          api.addDiscoveryQueryParam(param, {
            replace: true,
            refreshModel: true
          });
        });
        api.onPageChange(() => (0, _multilingualRoute.removeParam)(_multilingualRoute.localeParam, {
          ctx: this
        }));
        api.modifyClass("controller:preferences/interface", (_dec = (0, _decorators.default)(), _dec2 = (0, _decorators.default)("makeThemeDefault"), (_obj = {
          pluginId: "discourse-multilingual",
          availableLocales() {
            return this.site.interface_languages.map(l => ({
              value: l.locale,
              name: l.name
            }));
          },
          saveAttrNames(makeDefault) {
            let attrs = this._super(makeDefault);
            attrs.push("custom_fields");
            return attrs;
          },
          actions: {
            save() {
              if (!siteSettings.multilingual_content_languages_enabled) {
                return this._super();
              }

              // jQuery ajax removes empty arrays. This is a workaround
              let cl = this.model.custom_fields.content_languages;
              if (!cl || !cl.length) {
                this.set("model.custom_fields.content_languages", [""]);
              }
              return this._super().then(() => {
                const contentLanguages = this.site.content_languages;
                let rawUserLanguages = this.model.custom_fields.content_languages;
                let userLanguages = [];
                if (typeof rawUserLanguages === "string") {
                  rawUserLanguages = [rawUserLanguages];
                }
                if (rawUserLanguages) {
                  userLanguages = rawUserLanguages.map(locale => contentLanguages.find(l => l.locale === locale));
                }

                // See workaround above
                userLanguages = userLanguages.filter(l => l && (0, _multilingual.isContentLanguage)(l.locale, siteSettings));
                currentUser.set("content_languages", userLanguages);
              });
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "availableLocales", [_dec], Object.getOwnPropertyDescriptor(_obj, "availableLocales"), _obj), _applyDecoratedDescriptor(_obj, "saveAttrNames", [_dec2], Object.getOwnPropertyDescriptor(_obj, "saveAttrNames"), _obj)), _obj)));
        api.modifyClass("component:tag-drop", {
          pluginId: "discourse-multilingual",
          content: (0, _object.computed)("value.[]", function () {
            let values = (0, _helpers.makeArray)(this.value);
            if (this.selectKit.options.hiddenValues) {
              values = values.filter(val => !this.selectKit.options.hiddenValues.includes(val));
            }
            return values.map(x => {
              const translation = _I18n.default.tag_translations?.[_I18n.default.currentLocale()]?.[x] || x;
              return this.defaultItem(x, translation);
            });
          }),
          search(filter) {
            if (filter) {
              const translatedTags = searchTranslatedTags(filter, _I18n.default.currentLocale());
              const data = {
                q: filter,
                limit: this.get("siteSettings.max_tag_search_results")
              };
              return this.searchTags("/tags/filter/search", data, this._transformJson).then(originalResults => {
                return [...translatedTags, ...originalResults].slice(0, this.get("siteSettings.max_tag_search_results"));
              });
            } else {
              return (this.content || []).map(tag => {
                if (tag.id && tag.name) {
                  return tag;
                }
                return this.defaultItem(tag, tag);
              });
            }
          }
        });
        api.modifyClass("component:mini-tag-chooser", {
          pluginId: "discourse-multilingual",
          content: (0, _object.computed)("value.[]", function () {
            let values = (0, _helpers.makeArray)(this.value);
            if (this.selectKit.options.hiddenValues) {
              values = values.filter(val => !this.selectKit.options.hiddenValues.includes(val));
            }
            return values.map(x => {
              const translation = _I18n.default.tag_translations?.[_I18n.default.currentLocale()]?.[x] || x;
              return this.defaultItem(x, translation);
            });
          }),
          search(filter) {
            const translatedTags = searchTranslatedTags(filter, _I18n.default.currentLocale());
            const data = {
              q: filter || "",
              limit: this.get("siteSettings.max_tag_search_results"),
              categoryId: this.selectKit.options.categoryId
            };
            return this.searchTags("/tags/filter/search", data, this._transformJson).then(originalResults => {
              return [...translatedTags, ...originalResults].slice(0, this.get("siteSettings.max_tag_search_results"));
            });
          }
        });
        function tagDropCallback(item) {
          (0, _object.set)(item, "label", (0, _multilingualTag.multilingualTagTranslator)(item.name));
          return item;
        }
        function tagDropArrayCallback(content) {
          if (Array.isArray(content)) {
            return content.map(item => tagDropCallback(item));
          } else {
            return tagDropCallback(content);
          }
        }
        api.modifyClass("component:tag-drop", {
          pluginId: "discourse-multilingual",
          modifyContent(content) {
            return tagDropArrayCallback(content);
          }
        });
        api.modifyClass("component:sidebar/anonymous/categories-section", (_obj2 = {
          pluginId: "discourse-multilingual",
          get sectionLinks() {
            return this.sortedCategories.map(category => {
              const translatedName = category.custom_fields.translated_text[_I18n.default.currentLocale()] || category.name;
              (0, _object.set)(category, 'name', translatedName);
              return new _categorySectionLink.default({
                category,
                topicTrackingState: this.topicTrackingState,
                currentUser: this.currentUser
              });
            });
          }
        }, (_applyDecoratedDescriptor(_obj2, "sectionLinks", [_tracking.cached], Object.getOwnPropertyDescriptor(_obj2, "sectionLinks"), _obj2)), _obj2));
        api.modifyClass("component:sidebar/user/tags-section", (_obj3 = {
          pluginId: "discourse-multilingual",
          get sectionLinks() {
            const links = [];
            let tags;
            if (this.currentUser.sidebarTags.length > 0) {
              tags = this.currentUser.sidebarTags;
            } else {
              tags = this.site.navigation_menu_site_top_tags || [];
            }
            for (const tag of tags) {
              if (tag.pm_only) {
                links.push(new _customPmTagSectionLink.default({
                  tag,
                  currentUser: this.currentUser
                }));
              } else {
                links.push(new _customTagSectionLink.default({
                  tag,
                  topicTrackingState: this.topicTrackingState,
                  currentUser: this.currentUser
                }));
              }
            }
            return links;
          },
          showModal() {
            this.modal.show(_customTagsModal.default);
          }
        }, (_applyDecoratedDescriptor(_obj3, "sectionLinks", [_tracking.cached], Object.getOwnPropertyDescriptor(_obj3, "sectionLinks"), _obj3), _applyDecoratedDescriptor(_obj3, "showModal", [_object.action], Object.getOwnPropertyDescriptor(_obj3, "showModal"), _obj3)), _obj3));
        api.modifyClass("component:sidebar/anonymous/tags-section", (_obj4 = {
          pluginId: "discourse-multilingual",
          get sectionLinks() {
            return (this.site.anonymous_default_navigation_menu_tags || this.site.navigation_menu_site_top_tags).map(tag => {
              return new _customTagSectionLink.default({
                tag,
                topicTrackingState: this.topicTrackingState
              });
            });
          }
        }, (_applyDecoratedDescriptor(_obj4, "sectionLinks", [_tracking.cached], Object.getOwnPropertyDescriptor(_obj4, "sectionLinks"), _obj4)), _obj4));
        api.modifyClass("component:selected-name", {
          pluginId: "discourse-multilingual",
          label: (0, _object.computed)("title", "name", function () {
            if (this.selectKit.options.headerComponent === "tag-drop/tag-drop-header") {
              let item = tagDropCallback(this.item);
              return item.label || this.title || this.name;
            } else {
              return this._super(...arguments);
            }
          })
        });
        api.addTagsHtmlCallback(function (topic) {
          const contentLanguageTags = topic.content_language_tags;
          if (!siteSettings.multilingual_content_languages_enabled || !contentLanguageTags || !contentLanguageTags[0]) {
            return;
          }
          let html = '<div class="content-language-tags">';
          html += (0, _iconLibrary.iconHTML)("globe");
          contentLanguageTags.forEach(t => {
            html += (0, _renderTag.default)(t, {
              contentLanguageTag: true,
              style: "content-language-tag"
            }) + " ";
          });
          html += "</div>";
          return html;
        }, {
          priority: 100
        });

        // Replace the category link renderer with the custom one
        if (!currentUser) {
          var _obj5;
          api.replaceCategoryLinkRenderer(customCategoryLinkRenderer);
          api.modifyClass("component:category-row", (_obj5 = {
            pluginId: "discourse-multilingual",
            get categoryDescriptionText() {
              const currentLocale = _I18n.default.currentLocale();
              return this.category[`description_text_${currentLocale}`] || this.category.original_description_text;
            },
            get descriptionText() {
              if (this.categoryDescriptionText) {
                return this._formatDescription(this.categoryDescriptionText);
              }
            },
            _formatDescription(description) {
              const limit = 200;
              return `${description.slice(0, limit)}${description.length > limit ? "&hellip;" : ""}`;
            },
            // Ensure other properties are also set correctly
            get shouldDisplayDescription() {
              return this.displayCategoryDescription && this.categoryDescriptionText && this.categoryDescriptionText !== "null";
            },
            // Define the template within the component
            layout: (0, _templateFactory.createTemplateFactory)(
            /*
              
                        {{! template-lint-disable no-pointer-down-event-binding }}
                        <div
                          class={{concatClass
                            "category-row"
                            "select-kit-row"
                            (if this.isSelected "is-selected")
                            (if this.isHighlighted "is-highlighted")
                            (if this.isNone "is-none")
                          }}
                          role="menuitemradio"
                          data-index={{@index}}
                          data-name={{this.rowName}}
                          data-value={{this.rowValue}}
                          data-title={{this.title}}
                          title={{this.title}}
                          data-guid={{this.guid}}
                          {{on "focusin" this.handleFocusIn}}
                          {{on "mousedown" this.handleMouseDown}}
                          {{on "mouseenter" this.handleMouseEnter passive=true}}
                          {{on "click" this.handleClick}}
                          {{on "keydown" this.handleKeyDown}}
                          aria-checked={{this.isSelected}}
                          tabindex="0"
                        >
              
                          {{#if this.category}}
                            <div class="category-status" aria-hidden="true">
                              {{#if this.hasParentCategory}}
                                {{#unless this.hideParentCategory}}
                                  {{this.badgeForParentCategory}}
                                {{/unless}}
                              {{/if}}
                              {{this.badgeForCategory}}
                            </div>
              
                            {{#if this.shouldDisplayDescription}}
                              <div class="category-desc" aria-hidden="true">
                                {{dirSpan this.descriptionText htmlSafe="true"}}
                              </div>
                            {{/if}}
                          {{else}}
                            {{htmlSafe this.label}}
                          {{/if}}
                        </div>
                      
            */
            {
              "id": "kPgyXic5",
              "block": "[[[1,\"\\n\"],[1,\"            \"],[11,0],[16,0,[28,[37,0],[\"category-row\",\"select-kit-row\",[52,[30,0,[\"isSelected\"]],\"is-selected\"],[52,[30,0,[\"isHighlighted\"]],\"is-highlighted\"],[52,[30,0,[\"isNone\"]],\"is-none\"]],null]],[24,\"role\",\"menuitemradio\"],[16,\"data-index\",[30,1]],[16,\"data-name\",[30,0,[\"rowName\"]]],[16,\"data-value\",[30,0,[\"rowValue\"]]],[16,\"data-title\",[30,0,[\"title\"]]],[16,\"title\",[30,0,[\"title\"]]],[16,\"data-guid\",[30,0,[\"guid\"]]],[16,\"aria-checked\",[30,0,[\"isSelected\"]]],[24,\"tabindex\",\"0\"],[4,[38,2],[\"focusin\",[30,0,[\"handleFocusIn\"]]],null],[4,[38,2],[\"mousedown\",[30,0,[\"handleMouseDown\"]]],null],[4,[38,2],[\"mouseenter\",[30,0,[\"handleMouseEnter\"]]],[[\"passive\"],[true]]],[4,[38,2],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[38,2],[\"keydown\",[30,0,[\"handleKeyDown\"]]],null],[12],[1,\"\\n  \\n\"],[41,[30,0,[\"category\"]],[[[1,\"                \"],[10,0],[14,0,\"category-status\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasParentCategory\"]],[[[41,[51,[30,0,[\"hideParentCategory\"]]],[[[1,\"                      \"],[1,[30,0,[\"badgeForParentCategory\"]]],[1,\"\\n\"]],[]],null]],[]],null],[1,\"                  \"],[1,[30,0,[\"badgeForCategory\"]]],[1,\"\\n                \"],[13],[1,\"\\n  \\n\"],[41,[30,0,[\"shouldDisplayDescription\"]],[[[1,\"                  \"],[10,0],[14,0,\"category-desc\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n                    \"],[1,[28,[35,4],[[30,0,[\"descriptionText\"]]],[[\"htmlSafe\"],[\"true\"]]]],[1,\"\\n                  \"],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,\"                \"],[1,[28,[35,5],[[30,0,[\"label\"]]],null]],[1,\"\\n\"]],[]]],[1,\"            \"],[13],[1,\"\\n          \"]],[\"@index\"],false,[\"concatClass\",\"if\",\"on\",\"unless\",\"dirSpan\",\"htmlSafe\"]]",
              "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-multilingual/discourse/initializers/multilingual-initializer.js",
              "isStrictMode": false
            })
          }, (_applyDecoratedDescriptor(_obj5, "descriptionText", [_tracking.cached], Object.getOwnPropertyDescriptor(_obj5, "descriptionText"), _obj5)), _obj5));
        }
        api.reopenWidget("header", {
          defaultState() {
            return _jquery.default.extend(this._super(...arguments), {
              languageSwitcherMenuVisible: false
            });
          },
          toggleLangugeSwitcherMenu() {
            this.state.languageSwitcherMenuVisible = !this.state.languageSwitcherMenuVisible;
          }
        });
        api.decorateWidget("header-icons:before", helper => {
          const currentLocale = _I18n.default.currentLocale();
          const currentLanguage = localeMapping[currentLocale] || {
            name: currentLocale,
            icon: "translate"
          };
          let caretDownIcon = (0, _iconLibrary.iconNode)('caret-down');
          return helper.attach("header-dropdown", {
            title: "user.locale.title",
            icon: currentLanguage.icon,
            iconId: "language-switcher-menu-button",
            action: "toggleLangugeSwitcherMenu",
            active: helper.widget.parentWidget.state.languageSwitcherMenuVisible,
            contents: () => [helper.h('span.current-locale-text', [`${currentLanguage.name} `, caretDownIcon])]
          });
        });
        api.addHeaderPanel("language-switcher-menu", "languageSwitcherMenuVisible", (attrs, state) => ({
          attrs,
          state
        }));
        api.modifyClass("route:tag-groups-edit", {
          pluginId: "discourse-multilingual",
          setupController(controller, model) {
            this._super(controller, model);
            if (model.content_language_group) {
              controller.setupContentTagControls();
            }
          },
          actions: {
            tagsChanged() {
              this.refresh();
            }
          }
        });
        api.modifyClass("controller:tag-groups-edit", {
          pluginId: "discourse-multilingual",
          setupContentTagControls() {
            (0, _runloop.scheduleOnce)("afterRender", () => {
              $(".tag-groups-container").addClass("content-tags");
              $(".tag-group-content h1 input").prop("disabled", true);
              $(".content-tag-controls").appendTo(".tag-group-content");
            });
          }
        });
        if (currentUser && currentUser.admin) {
          api.modifyClass("component:table-header-toggle", {
            pluginId: "discourse-multilingual",
            click(e) {
              if ($(e.target).parents(".toggle-all").length) {
                return true;
              } else {
                return this._super(e);
              }
            }
          });
        }
      });
    }
  };
});