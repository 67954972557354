define("discourse/plugins/discourse-translator/discourse/helpers/translated-title-icon", ["@ember/template", "discourse-common/lib/helpers", "discourse/lib/utilities", "discourse-common/lib/icon-library", "I18n"], function (_template, _helpers, _utilities, _iconLibrary, _I18n) {
  "use strict";

  (0, _helpers.registerRawHelper)("translated-title-icon", topic => {
    const localeMapping = {
      en: {
        name: "English"
      },
      fr: {
        name: "Français"
      },
      de: {
        name: "Deutsch"
      },
      nl: {
        name: "Nederlands"
      },
      pl_PL: {
        name: "Polski"
      }
    };
    const languageCode = topic.title_language;
    const language = localeMapping[languageCode]?.name || languageCode;
    const siteSettings = (0, _helpers.helperContext)().siteSettings;
    const icon = (0, _iconLibrary.iconHTML)("globe");
    const title = _I18n.default.t("translator.translated_from", {
      language: language,
      translator: siteSettings.translator
    });
    const original = (0, _utilities.escapeExpression)(topic.original_title);
    return (0, _template.htmlSafe)(`<span class='translated-title-icon' title='${title}: ${original}'>${icon}</span>`);
  });
});